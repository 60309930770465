<template>
  <div class="Support">


  </div>
</template>

<style lang="scss">
.Support{
  height: 500vh;
}
</style>

<script>
  export default {
    name: 'Support',
    data(){
      return {

      }
    },
    mounted(){

    },
    methods:{

    },
    computed:{
      
    }
  }
</script>


